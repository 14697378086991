.Meal {
    border-radius: 10px;
    background-color: #dfdfdf;
    padding: 10px;
    margin: 5px;
    color: black;
    list-style-type: none;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;

}

.padding {
    margin-left: 10px;
}