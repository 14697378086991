.Modal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);

    display: flex;
    direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
}

.Modal-inner {
    background-color: #282c34;
    padding: 10px;
    margin: 10px;
    border-radius: 25px;
}