.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-around;
  font-size: calc(10px + 2vmin);
  color: white;
}

button {
  border: 0px solid #000000;
  border-radius: 5px;
  padding: 10px;
  margin: 5px;
  font-size: calc(10px + 2vmin);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.SpinnerContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

input {
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
  font-size: calc(10px + 2vmin);
}