/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.AddMeal {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: 10px;
    padding: 10px;
}

.MealButtons {
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.MealButtons button {
    background-color: rgb(70, 140, 85);
}

.CancelButton {
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: right;
}

.CancelButton button {
    background-color: rgb(204, 114, 76);
}

.autocomplete-items {
    border: 1px solid #999;
    list-style: none;

    margin: 0;
    padding-left: 0;
    position: absolute;
}

.autocomplete-items li {
    margin: 0;
    border: 1px solid #999;
    background-color: #383838;
}

.autocomplete-items button {
    background: none;
    padding: 10px;
    margin: 0;
    border-radius: 0px;
    font-size: calc(10px + 1vmin);
    color: rgb(223, 223, 223);
    box-shadow: none;
}