.Day {
    background-color: #303030;
    border-radius: 25px;
    padding: 10px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.Day ul {
    list-style: none;
    padding-left: 0;
}

.BottomMeals {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.BottomMeals button {
    margin: 10px;
    padding: 10px;
    background-color: #8d9981;
}